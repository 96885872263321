<template>
  <div>
    <a-table
      :columns="columns"
      :data-source="items"
      size="small"
      :pagination="pagination"
      @change="tableChange"
      :loading="loading"
    >
      <div slot="date" slot-scope="value">{{ moment(value).format("YYYY-MM-DD") }}</div>
      <div slot="sales_order_number" slot-scope="value, item">
        <a @click="jumpSalesOrderDetailPage(item)">{{ value }}</a>
      </div>
    </a-table>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "FlowPane",
  props: ["items", "loading", "pagination"],
  data() {
    return {
      moment,
      columns: [
        {
          title: "物料名称",
          dataIndex: "goods_name",
          key: "goods_name",
        },
        {
          title: "物料编号",
          dataIndex: "goods_number",
          key: "goods_number",
        },
        {
          title: "规格",
          dataIndex: "goods_spec",
          key: "goods_spec",
        },
        {
          title: "分类名称",
          dataIndex: "category_name",
          key: "category_name",
        },
        {
          title: "单位",
          dataIndex: "unit_name",
          key: "unit_name",
        },
        {
          title: "销售单价",
          dataIndex: "sales_price",
          key: "sales_price",
        },
        {
          title: "总金额",
          dataIndex: "total_amount",
          key: "total_amount",
        },
        {
          title: "销售单号",
          dataIndex: "sales_order_number",
          key: "sales_order_number",
          scopedSlots: { customRender: "sales_order_number" },
        },
        {
          title: "客户",
          dataIndex: "client_name",
          key: "client_name",
        },
        {
          title: "制单人",
          dataIndex: "handler_name",
          key: "handler_name",
        },
        {
          title: "制单时间",
          dataIndex: "handle_time",
          key: "handle_time",
        },
      ],
    };
  },
  methods: {
    tableChange(pagination, filters, sorter) {
      this.$emit("tableChange", pagination, filters, sorter);
    },
    openInvoice(id) {
      window.open(`/invoice/sales?id=${id}`);
    },
    jumpSalesOrderDetailPage(item) {
      this.$router.push({ path: "/sale/sale_record_detail", query: { id: item.sales_order } });
    },
  },
};
</script>
